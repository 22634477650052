import type { BankAccount } from '@develit-io/fubex-exchange-prisma'

export function capitalizeInitial (name: string) {
  return name.charAt(0).toUpperCase() + name.slice(1)
}

export function formatPercentChange (n: number): string {
  const prefix = n < 0 ? '-' : '+'
  const roundedN = Math.abs(n).toFixed(1).replace('.', ',')
  const suffix = '%'

  return `${prefix} ${roundedN} ${suffix}`
}

// function to format FIAT price
export function formatFiat (price: number, fractionDigits = 2): string {
  if (price === Math.floor(price))
    fractionDigits = 0

  const formattedPrice = price.toLocaleString('cs-CZ', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    useGrouping: true,
  })

  const normalizedResult = formattedPrice.replace(/\s/g, ' ')
  return normalizedResult
}

// function to format CRYPTO price
export function formatCrypto (price: number): string {
  let fractionDigits = 8
  if (price === Math.floor(price))
    fractionDigits = 0

  const formattedPrice = price.toLocaleString('cs-CZ', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    useGrouping: false,
  })

  const normalizedResult = formattedPrice.replace(/\s/g, ' ')
  return normalizedResult
}

export function formatPriceByCurrency (price: number, type: string) {
  if (['CZK', 'EUR', 'USD'].includes(type))
    return `${formatFiat(price)} ${type}`
  else
    return `${formatCrypto(price)} ${type}`
}

// function to format price in Czech Crowns
export function formatPriceInCzk (price: number, fractionDigits?: number): string {
  const formattedPrice = price.toLocaleString('cs-CZ', {
    minimumFractionDigits: fractionDigits ?? 2,
    maximumFractionDigits: fractionDigits ?? 2,
    useGrouping: true,
    style: 'currency',
    currency: 'CZK',
  })

  const normalizedResult = formattedPrice.replace(/\s/g, ' ')
  return normalizedResult
}

// function to format price in EUR
export function formatPriceInEur (price: number, fractionDigits?: number): string {
  const formattedPrice = price.toLocaleString('cs-CZ', {
    minimumFractionDigits: fractionDigits ?? 2,
    maximumFractionDigits: fractionDigits ?? 2,
    useGrouping: true,
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'code',
  })

  const normalizedResult = formattedPrice.replace(/\s/g, ' ')
  return normalizedResult
}

// floor a number to a certain decimal positions
export function roundDown (number: number, decimalPlaces: number): number {
  const factor = 10 ** decimalPlaces
  return Math.floor(number * factor) / factor
}

// round up a number to a certain decimal positions
export function roundUp (number: number, decimalPlaces: number): number {
  const factor = 10 ** decimalPlaces
  return Math.ceil(number * factor) / factor
}

// round mathematically a number to a certain decimal positions
export function roundMath (number: number, decimalPlaces: number): number {
  const factor = 10 ** decimalPlaces
  return Math.round(number * factor) / factor
}

export function formatDateTime (inputDate: Date | string, hideTime?: boolean): string {
  let date: Date

  if (typeof inputDate === 'string')
    date = new Date(inputDate)

  else
    date = inputDate

  let props: any = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }

  if (!hideTime) {
    props = {
      ...props,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }
  }

  const formattedDate = date.toLocaleString('cs-CZ', props)

  const normalizedResult = formattedDate.replace(/\s/g, ' ')
  return normalizedResult
}

export function stripHTML (html: string | undefined | null): string {
  if (!html)
    return ''
  return html.replace(/<[^>]*>/g, '')
}

// this function removes whitespaces from a string
export function removeWhitespaces (str: string) {
  return str.replace(/\s+/g, '')
}

// this function formats IBAN to be human-readable, it inserts spaces every 4 characters
export function formatHumanReadableIban (input: string): string {
  return input.replace(/(.{4})/g, '$1 ').trimEnd()
}

// this function return human readable iban if possible, otherwise full bank account
export function humanReadableIbanOrFullBankAccount (b: BankAccount) {
  if (b.iban) return formatHumanReadableIban(b.iban)
  return fullBankAccount(b)
}
